import axiosIntents from '@/utils/axios-intents'
import axios from 'axios'
import dynStorage from '@/utils/storage-dynamic'

export default {
    state: {},

    getters: {},

    actions: {
        LinkTrueRewardsNumber({ getters }, { is_to_link = false, RewardsEmail, Password } = {}) {
            // TODO done only for demo purposes
            if (!is_to_link) {
                return new Promise(resolve => {
                    const REWARDS_CARD_UUIDS = dynStorage.get('REWARDS_CARD_UUIDS', true)
                    const CustomerUUID = getters.current_account_uid
    
                    if (REWARDS_CARD_UUIDS) {
                        delete REWARDS_CARD_UUIDS[CustomerUUID]
    
                        dynStorage.set('REWARDS_CARD_UUIDS', REWARDS_CARD_UUIDS, false, true)
                    }

                    setTimeout(() => {
                        resolve('')
                    }, 1200);
                })
            }

            return axios.post(
                'https://rewardsvc-wggcqrj4kq-ts.a.run.app/api/v3/claim_rewards_card',
                {
                    RewardsEmail,
                    Password,
                    SPID: 67,
                }
            ).then(({data}) => {
                const REWARDS_CARD_UUID = data?.UUID || ''

                if (REWARDS_CARD_UUID) {
                    const REWARDS_CARD_UUIDS = dynStorage.get('REWARDS_CARD_UUIDS', true) || {}
                    const CustomerUUID = getters.current_account_uid
    
                    REWARDS_CARD_UUIDS[CustomerUUID] = REWARDS_CARD_UUID

                    dynStorage.set('REWARDS_CARD_UUIDS', REWARDS_CARD_UUIDS, false, true)
                }

                return REWARDS_CARD_UUID
            })
        },

        UpdateMVNECustomer({}, { FirstName, LastName, ExternalRef5, Email, MobileNumber = '' } = {}) {
            return axiosIntents.put('/customer/:uuid', { FirstName, LastName, ExternalRef5, Email, MobileNumber })
                .catch(error => {
                    console.log('UpdateMVNECustomer error', error)
                })
        },

        SendEmailVerification({}, { resend = false } = {}) {
            return axiosIntents.get(`/customer/:uuid/email_verification/${resend ? 'resend' : 'send'}`)
                .catch(error => {
                    console.log('SendEmailVerification Error:', error)

                    return Promise.resolve(error)
                })
        },

        ConfirmEmailVerification({ getters }, { Secret } = {}) {
            if (!Secret) return Promise.reject('No Code')
            
            const CustomerUUID = getters.current_account_uid

            return axiosIntents.get(`/customer/:uuid/email_verification/confirm`, { params: { Secret, CustomerUUID } })
                .catch(error => {
                    console.log('ConfirmEmailVerification Error:', error)

                    return Promise.reject(error)
                })
        },

        sendMFACode() {
            return axiosIntents.post('/customers/:uuid/mfa')
                .catch(error => {
                    console.log('sendMFACode Error:', error)

                    return Promise.resolve(error)
                })
        },

        verifyMFACode({}, { code } = {}) {
            if (!code) return Promise.reject('No Code')
            
            return axiosIntents.put('/customers/:uuid/mfa', { code })
                .catch(error => {
                    console.log('verifyMFACode Error:', error)
    
                    return Promise.reject(error)
                })
        },

        IdentifyCustomerWithToken({ getters }, { identity_token } = {}) {
            console.log('IdentifyCustomerWithToken', identity_token)

            if(!identity_token) Promise.reject('No identity_token')

            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve({identity_token})

                    // reject('Identification error')
                }, 1200);
            })
        },

        AcceptAccessInvitation({}, code) {
            if (!code) return Promise.reject('No Code')

            return axiosIntents.post('/api/customers/v1/invitation', { code }).then(response => {
                if (!response?.data?.CustomerUUID) return Promise.reject('No CustomerUUID');

                return response.data.CustomerUUID
            })
        },

        UpdateCustomerServiceNickname({}, nicknames_to_update = []) {
            const nicknames_to_update_promises = nicknames_to_update.map(service => {
                const CustomerUUID = service.CustomerUUID
                const MobileUUID = service.MobileUUID
                const nickname = service.value

                return axiosIntents.put(`/api/customers/v1/account/${CustomerUUID}/nicknames/mobile/${MobileUUID}`, `"${nickname}"`)
            })

            return Promise.allSettled(nicknames_to_update_promises).then(results => {
                let has_errors = false

                results.forEach((result, index) => {
                    if (result.status == 'rejected') {
                        has_errors = true
                        nicknames_to_update[index].error = true
                    }
                })

                if (has_errors) return Promise.reject(has_errors);

                return Promise.resolve(results)
            })
        },

        AccessTemporaryLogin({}, code) {
            if (!code) return Promise.reject('No Code')

            return axiosIntents.post('/api/customers/v1/login/code', { code }).then(response => {
                if (!response?.data?.Firebase_identity) return Promise.reject('No Firebase identity');

                return response.data.Firebase_identity
            })
        },
    },

    mutations: {},
}