<template>
    <div
        class="movable-element"
        :style="elementStyle"
        @mousedown="startDrag"
        @touchstart="startDrag"
    >
        <slot></slot>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isDragging: false,
            position: { x: 0, y: 0 },
            startOffset: { x: 0, y: 0 },
        }
    },

    beforeDestroy() {
        window.removeEventListener("mousemove", this.onDrag)
        window.removeEventListener("mouseup", this.endDrag)
        window.removeEventListener("touchmove", this.onDrag)
        window.removeEventListener("touchend", this.endDrag)
    },

    computed: {
        elementStyle() {
            return {
                transform: `translate(${this.position.x}px, ${this.position.y}px)`,
            }
        },
    },

    methods: {
        startDrag(event) {
            this.isDragging = true;

            const clientX = event.type === "touchstart" ? event.touches[0].clientX : event.clientX
            const clientY = event.type === "touchstart" ? event.touches[0].clientY : event.clientY

            this.startOffset.x = clientX - this.position.x
            this.startOffset.y = clientY - this.position.y

            window.addEventListener("mousemove", this.onDrag)
            window.addEventListener("mouseup", this.endDrag)
            window.addEventListener("touchmove", this.onDrag, { passive: false })
            window.addEventListener("touchend", this.endDrag)
        },

        onDrag(event) {
            if (!this.isDragging) return;

            const clientX = event.type === "touchmove" ? event.touches[0].clientX : event.clientX
            const clientY = event.type === "touchmove" ? event.touches[0].clientY : event.clientY

            this.position.x = clientX - this.startOffset.x
            this.position.y = clientY - this.startOffset.y

            if (event.type === "touchmove") event.preventDefault()
        },

        endDrag() {
            this.isDragging = false

            window.removeEventListener("mousemove", this.onDrag)
            window.removeEventListener("mouseup", this.endDrag)
            window.removeEventListener("touchmove", this.onDrag)
            window.removeEventListener("touchend", this.endDrag)
        },
    },
}
</script>

<style>
.movable-element {
    cursor: grab;
    user-select: none;
    touch-action: none;
}

.movable-element:active {
    cursor: grabbing;
}
</style>
