import dynStorage from '@/utils/storage-dynamic';

import { firestoreAction } from 'vuexfire';
import { db } from '@/utils/firebase.js';

const RELOAD_CACHE_TIME = '5s';

const displayName = (account) => {
	if (!account) return '';

	const filteredFirstNameAndLastName = [account.FirstName, account.LastName].filter((x) => x);

	if (filteredFirstNameAndLastName.length === 0) return account.Email || '';
	if (filteredFirstNameAndLastName.length === 1) return filteredFirstNameAndLastName[0];
	if (account.FirstName.length + account.LastName.length > 20) return account.FirstName;

	return `${account.FirstName} ${account.LastName}`
};

export default {
  state: {
    current_account_uid: null,
    current_account: null,
    is_account_email_verified: false,
    is_account_suspended: false,

    temporary_login_time_end: 0,
    is_show_temporary_login_timer: false,
  },

  getters: {
    is_account: (state) => Boolean(state.current_account),
    is_account_postpay: (state, getters) => getters.accountType === "postpay",
    is_account_email_verified: (state, getters) => Boolean(state.current_account?.EmailVerified) || state.is_account_email_verified || getters.account_email_ends_in_nullnull || getters.is_app_environment_email_verified || getters.is_auth_user_social_logged_in,
    is_account_suspended: (state) => state.is_account_suspended,
    current_account: (state) => state.current_account,
    current_account_uid: (state) => state.current_account_uid || state.current_account && state.current_account.UUID,
    account_first_name: (state) => state.current_account?.FirstName || '',
    account_last_name: (state) => state.current_account?.LastName || '',
    account_email: (state) => state.current_account?.Email || '',
    account_email_ends_in_nullnull: (state) => (state.current_account?.Email || '').endsWith('@null.null'),
    account_phone: (state) => state.current_account?.MobileNumber || '',
    account_display_name: (state) => displayName(state.current_account),
    account_services: (state) => state.current_account?.services || {},
    account_external_ref5: (state) => state.current_account?.ExternalRef5 || '',
    true_rewards_number: (state) => state.current_account?.ExternalRef5 || '',
    temporary_login_time_end: (state) => state.temporary_login_time_end,
    is_show_temporary_login_timer: (state) => state.is_show_temporary_login_timer,

	user_profile_creds: (state, getters) => ({
		first_name: getters.account_first_name,
		last_name: getters.account_last_name,
		email: getters.account_email,
		phone: getters.account_phone,
	}),
  },

  actions: {
    BindCurrentAccount: firestoreAction(({ bindFirestoreRef, getters, dispatch }, uid) => {
      return bindFirestoreRef('current_account', db.collection(getters.collection_customers_name).doc(uid))
                .then(() =>  dispatch('GetCurrentAccountSuspendedState'));
    }),

    UnbindCurrentAccount: firestoreAction(({ unbindFirestoreRef }) => {
      return unbindFirestoreRef('current_account');
    }),

    GetCurrentAccountSuspendedState({ getters, commit }) {
        return db
            .collection(getters.collection_customers_name)
            .doc(getters.current_account_uid)
            .collection('account_state')
            .doc(getters.current_account_uid)
            .get()
            .then(doc => {
                const account_suspended_state = doc?.data();

                const is_account_suspended = account_suspended_state?.AccountState == 'Suspended';

                commit('SET_IS_ACCOUNT_SUSPENDED', is_account_suspended);

                return Promise.resolve(account_suspended_state);
            })
            .catch((error) => {
                console.error('Error getting CurrentAccountSuspendedState:', error);

                commit('SET_IS_ACCOUNT_SUSPENDED', false);

                return Promise.resolve(false)
            });
    },

    SelectAccount({ getters, dispatch, commit }, uuid) {
		commit('SAVE_CURRENT_ACCOUNT_UID', uuid);
        commit('RESET_CUSTOMER_GROUPS_AND_MEMBERS');
        commit('RESET_MOBILE_GROUPS_AND_MEMBERS');
        commit('RESET_CUSTOMER_INVITES');
        commit('RESET_MOBILE_INVITES');
        commit('RESET_PAYMENT_HISTORY');
        commit('resetInvoices_v3')

		return dispatch('BindCurrentAccount', uuid)
            .then(_ => {
                dispatch('eshopCartLoad_v2').catch(()=>{})

                if (getters.is_app_support_service_intercom) {
                    dispatch('LogInIntercomChat')
                } else {
                    dispatch('LogInZendeskChat')
                }

			    return dispatch('mockupCustomer');
		    }).catch(error => {
			    console.log(error)
			    return Promise.reject(error)
		    })
    },

    QuickReloadSupport({ state, commit, dispatch, getters }) {
      window.addEventListener('unload', () => {
        dynStorage.set('temporary-login-time-end', state.temporary_login_time_end, RELOAD_CACHE_TIME, true);
        dynStorage.set('is-show-temporary-login-timer', state.is_show_temporary_login_timer, RELOAD_CACHE_TIME, true);
      });

      const temporary_login_time_end = dynStorage.get('temporary-login-time-end', true);
      if (temporary_login_time_end) {
        commit('SET_TEMPORARY_LOGIN_TIME_END', temporary_login_time_end);
      }

      const is_show_temporary_login_timer = dynStorage.get('is-show-temporary-login-timer', true);
      if (is_show_temporary_login_timer) {
        commit('SET_IS_SHOW_TEMPORARY_LOGIN_TIMER', is_show_temporary_login_timer);
      }

      const current_account_uid = dynStorage.get('current-account-uid', true);
      if (current_account_uid) {
        commit('SAVE_CURRENT_ACCOUNT_UID', current_account_uid);

        // dispatch('eshopCartLoad_v2').catch(()=>{})
      }

      const mockup_user = dynStorage.get('mockup-user', true);
      if (mockup_user) {
        commit('saveMockupUser', mockup_user);
      }

	  const account_customers_uuids = dynStorage.get('acc-customers-uuids', true);
      if (account_customers_uuids) {
        commit('SET_ACCOUNT_CUSTOMERS_UUIDS', account_customers_uuids);
      }

	  const is_app_header_hidden = dynStorage.get('is_app_header_hidden', true);
      if (is_app_header_hidden) {
        commit('SET_IS_APP_HEADER_HIDDEN', true);
      }

	  const is_mobile_onboarding = dynStorage.get('is_mobile_onboarding', true);
      if (is_mobile_onboarding) {
        commit('SET_IS_MOBILE_ONBOARDING', true);
      }

	  const platform = dynStorage.get('platform', true);
      if (platform) {
        commit('SET_PLATFORM', platform);
      }

	  const language = dynStorage.get('language', true);
      if (language) {
        dispatch('SetLanguage', { language, updateMockupUserLanguage: false });
      }

	  const prepay_product = dynStorage.get('prepay_product', true);
      if (prepay_product) {
        commit('savePrepayProduct', prepay_product);
      }

	  const postpay_product = dynStorage.get('postpay_product', true);
      if (postpay_product) {
        commit('savePostpayProduct', postpay_product);
      }

	  const customer_current_product = dynStorage.get('customer_current_product', true);
      if (customer_current_product) {
        commit('SET_CUSTOMER_CURRENT_PRODUCT', customer_current_product);
      }

	  const customer_current_mobile_product_uuid = dynStorage.get('customer_current_mobile_product_uuid', true);
      if (customer_current_mobile_product_uuid) {
        commit('SET_CUSTOMER_CURRENT_MOBILE_PRODUCT_UUID', customer_current_mobile_product_uuid);
      }

	  const onboarding_current_step = dynStorage.get('onboarding_current_step', true);
      if (onboarding_current_step) {
        commit('SET_ONBOARDING_CURRENT_STEP', onboarding_current_step);
      }

	  const sim = dynStorage.get('sim', true);
      if (sim?.product || sim?.sim_type) {
        commit('SET_SIM', sim);
      }

	  const is_customer_current_addons_payg = dynStorage.get('is_customer_current_addons_payg', true);
      if (is_customer_current_addons_payg) {
        commit('SET_IS_CUSTOMER_CURRENT_ADDONS_PAYG', is_customer_current_addons_payg);
      }
    },
  },

  mutations: {
    SAVE_CURRENT_ACCOUNT_UID(state, uid) {
        state.current_account_uid = uid;

        this.commit('RESET_CATALOG_SERVICES')
        this.commit('RESET_USAGE_CHARGES')

        if (uid) {
            dynStorage.set('current-account-uid', uid, null, false);
        } else {
            dynStorage.remove('current-account-uid');
        }
    },

    SAVE_CURRENT_ACCOUNT(state, account) {
      state.current_account = account;
    },

    SET_IS_ACCOUNT_EMAIL_VERIFIED(state, is_account_email_verified) {
        state.is_account_email_verified = is_account_email_verified;
    },

    SET_IS_ACCOUNT_SUSPENDED(state, is_account_suspended) {
        state.is_account_suspended = is_account_suspended;
    },

    SET_TEMPORARY_LOGIN_TIME_END(state, temporary_login_time_end) {
        state.temporary_login_time_end = temporary_login_time_end;
    },

    RESET_TEMPORARY_LOGIN_TIME_END(state) {
        state.temporary_login_time_end = 0;
        dynStorage.remove('temporary-login-time-end');
    },

    SET_IS_SHOW_TEMPORARY_LOGIN_TIMER(state, is_show_temporary_login_timer) {
        state.is_show_temporary_login_timer = is_show_temporary_login_timer;
    },

    FIREBASE_LOGOUT(state) {
	    dynStorage.remove('current-auth-user-uid');
        dynStorage.remove('current-account-uid');
	    dynStorage.remove('mockup-user');
	    dynStorage.remove('acc-customers-uuids');
        dynStorage.remove('is_app_header_hidden');
	    dynStorage.remove('is_mobile_onboarding');
	    dynStorage.remove('platform');
	    dynStorage.remove('prepay_product');
	    dynStorage.remove('postpay_product');
	    dynStorage.remove('customer_current_product');
	    dynStorage.remove('customer_current_mobile_product_uuid');
	    dynStorage.remove('onboarding_current_step');
	    dynStorage.remove('sim');
	    dynStorage.remove('is_customer_current_addons_payg');

		sessionStorage.removeItem('token');
        sessionStorage.removeItem('onboardingAdoptionResume_JWT');
        sessionStorage.removeItem('adoption_uuid');
        sessionStorage.removeItem('approval_token');

        state.current_account = null;
        state.current_account_uid = null;
        state.is_account_email_verified = false;
        state.is_account_suspended = false;
    },
  },
};
